import {BaseRequest} from "@/request/BaseRequest";

class IntegralExchangeSet extends BaseRequest {

    public requestPath: string = '/marketing/integralExchangeSet';

    info(): any {
        return this.post(`${this.requestPath}/info`)
    }

    update(data: {
        exchangeGoodsMaxRate: number,
        exchangeIntegralValue: number,
        integralGoodsValue: number
    }): any {
        return this.post(`${this.requestPath}/update`, data)
    }

}

const c = new IntegralExchangeSet();
export {c as IntegralExchangeSet};