


















































import {IntegralExchangeSet} from "@/request/marketing/IntegralExchangeSet";

export default {
    data() {
        return {
            loading: [false, false, false],
            formData: {
                exchangeGoodsMaxRate: 1,
                exchangeIntegralValue: 1,
                integralGoodsValue: 1
            },
            editExchangeGoodsMaxRate: false,
            editExchangeIntegralValue: false,
            editIntegralGoodsValue: false,
            tempIntegralGoodsValue: 1,
        }
    },
    created() {
        let self: any = this;
        const msg = self.$Message.loading({
            content: '加载中...',
            duration: 0
        })
        IntegralExchangeSet.info().then((body: any) => {
            setTimeout(msg, 500);
            if (body.code === 0) {
                self.formData = body.data;
            }
        })
    },
    methods: {
        handleEditExchangeGoodsMaxRate() {
            let self: any = this;
            self.editExchangeGoodsMaxRate = !self.editExchangeGoodsMaxRate;
            if (!self.editExchangeGoodsMaxRate) {
                self.loading[2] = true;
                self.submit();
            }
        },
        handleEditExchangeIntegralValue() {
            let self: any = this;
            self.editExchangeIntegralValue = !self.editExchangeIntegralValue;
            if (!self.editExchangeIntegralValue) {
                self.loading[1] = true;
                self.submit();
            }
        },
        handleEditIntegralGoodsValue() {
            let self: any = this;
            self.editIntegralGoodsValue = !self.editIntegralGoodsValue;
            if (!self.editIntegralGoodsValue) {
                self.formData.integralGoodsValue = self.tempIntegralGoodsValue * 100;
                self.loading[0] = true;
                self.submit();
            } else {
                self.tempIntegralGoodsValue = (self.formData.integralGoodsValue / 100).toFixed(2);
            }
        },
        submit() {
            let self: any = this;
            IntegralExchangeSet.update(self.formData).then((body: any) => {
                if (body.code === 0) {
                    self.loading = [false, false, false];
                    self.$Message.info('修改成功');
                }
            })
        }
    }
}
